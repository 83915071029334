@import '../../common/styles/variables';

.dev-checkbox .dev-checkbox-item-wrapper,
.dev-radio .dev-radio-item-wrapper {
    > label {
        &:before {
            border: 1px solid #c8c2b9;
        }

        &:hover:before {
            border: 1px solid #c1d1e0;
            box-shadow: 0px -0.3px 2px 2px rgba(249, 198, 133, 0.52);
        }
    }
    &.checked > label:before {
        background: #f69920;
        border: 1px solid #f69920;
    }
    &.checked > label:after {
        color: white;
    }
}
