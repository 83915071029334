@import '../../common/styles/variables';

.dev-filter-single-select{
    .container{
        border-color: #eee;
        box-shadow: 1px 1px 1px #eee;
        background-color: $default;
        
        input:active,input:focus{
            border-color: $orange-hover;
        }
        
        .fa.fa-search{
            color: $orange-text;
        }
        
        li{
            a{
                color: $orange-text; 
                
                em{
                    border-bottom-color: $orange-text;
                }
            }
            
            &.active{
                a{
                    background-color: $orange-hover !important;
                    color: $default !important;
                    
                    em{
                        border-bottom-color: $default;
                    }
                }
            }
            
            &.hover{
                a{
                    background-color: #f7f7f7;
                }
            }
        }
    }
}