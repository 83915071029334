.copy-code-btn {
    position: absolute;
    float: right;
    top: 0px;
    right: 0px;
    border-radius: 0px;
    background-color: #f69921;
    border-color: #f69921;

    &:hover {
        background-color: #e48e1f;
        border-color: #e48e1f;
    }
}
