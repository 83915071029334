$anchor-line-back: #f3e7d9;
$anchor-circle-back: #f69920;

.dev-wiki-wrapper {
    color: #4e555b;

    ul.dev-wiki-anchor.not-empty {
        &:before {
            background-color: $anchor-circle-back;
        }
        &:after {
            opacity: 0.5;
            background-color: $anchor-line-back;
            border: 2px solid $anchor-line-back;
        }

        li.active {
            // background: $anchor-line-back;
            // border: 1px solid $anchor-line-back;

            &:before {
                background: $anchor-circle-back;
            }
        }
    }

    .dev-wiki {

        a {
            color: $anchor-circle-back;
        }

        ul li:before {
            background-color: #1bbbb9;
        }

    }
}
