@import '../../common/styles/variables';

$main-back-color: #f4f4f4;
$main-hover-color: rgba(221, 221, 221, 0.52);
$border-color: #d2dce7;
$a-color: #0d3c5d;
$a-hover: #20b3f3;
$tag-border: #ffe2bd;
$btn-feedback: #f69920;
$btn-feedback-hover: #ea8d12;
$question-item-back: #f7f6f3;
$question-item-hover: #ecebe7;
$question-content-color: #4e555b;
$tag-color:  #f39b21;
$tag-selected: #f1c287;
$question-action: #1bbbb9;

#product-main .dev-question-nav {
    background: $main-back-color;
    border: 1px solid $border-color;

    ul li a {
        color: $a-color;

        &:hover {
            color: $a-hover;
            background-color: $main-hover-color !important;
        }
    }
    ul li.active {
        background: $main-hover-color;
        a {
            color: $a-hover;
        }
    }
}

.question-tags-list {
    vertical-align: middle;
    .tag {
        background-color: $tag-border;
        border: 1px solid $tag-border;
        color: $tag-color;
    }
    .tag.selected {
        background-color: $tag-selected;
    }
}

.question-wrapper {
    .tag-border {
        border: 1px solid $tag-border;
    }
    .btn-feedback {
        background: $btn-feedback;

        &:hover {
            background: $btn-feedback-hover;
        }
    }

    .question-tags .tag {
        background-color: $tag-border;
        border: 1px solid $tag-border;
        color: $tag-color;
        &.selected {
            background-color: $tag-selected;
        }
    }
    .question-tags .tag-nav {
        border: 1px solid $tag-border;
    }
    .question-item {
        background: $question-item-back;

        &:hover {
            background: $question-item-hover;
            pre {
                background: $question-item-hover;
            }
        }

        .division {
            border-right: 1px solid $border-color;
            border-left: 1px solid #fff;
        }
    }
    .question-item, .question-detail {
        .action {
            background: $question-action;
        }

        .question .content {
            color: $question-content-color;
        }

        .answer .action {
            background: $btn-feedback;
        }
        .answer .time {
            color: rgba(0, 0, 0, 0.5);
        }
        .answer .content {
            color: #000000;
        }

        .tags .tag-nav {
            border: 1px solid $tag-border
        }
    }
}
.content-init {
    background: $question-item-back;
    padding: 6px 18px;
    border-radius: 5px;
}
.question-detail .question {
    .content {
        @extend .content-init;
    }
    .content:before {
        border-color: transparent $question-item-back transparent transparent;
    }
}
.question-detail .answer {
    .content {
        @extend .content-init;
    }
    .content:before {
        border-color: transparent transparent transparent $question-item-back;
    }
}

.question-type-wrapper .question-item-wrapper .question-item {
    background: $question-item-back;
    .action {
        background: $question-action;
    }
    .answer .action {
        background: $btn-feedback;
    }
    .tags .tag-nav {
        border: 1px solid $tag-border;
    }
}
