@import '../../common/styles/variables';

.dev-anchor-wrapper {
    color: #4e555b;
    
    .dev-directory{
        ul.not-empty {
            &:before {
                background-color: $directory-circle-orange;
            }
            &:after {
                opacity: 0.5;
                background-color: $directory-line-orange;
                border: 2px solid $directory-line-orange;
            }
    
            li.active {
                // background: $directory-line-orange;
                // border: 1px solid $directory-line-orange;
    
                &:before {
                    background: $directory-circle-orange;
                }
            }
        }        
    }
}
