@import '../../common/styles/variables';
@import '../variables';

.dev-treetable {
    input[type=checkbox]:not([disabled]) + label:hover,
    input[type=checkbox]:not([disabled]):checked + label {
        border: 1px solid $orange-checkbox-bg;
    }

    input[type=checkbox]:checked + label {
        background: $orange-checkbox-bg;
    }
}
